import clsx from 'clsx'
import { useControlField, useField } from 'remix-validated-form'

import React from 'react'
import { Input } from '~/components/ui'
import FieldContainer from './FieldContainer'

export type TextFieldProps = {
  className?: string
  label?: string
  name: string
  type?: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  prefix?: string
  suffix?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
}

const TextField = React.forwardRef(
  (props: TextFieldProps, ref?: React.ForwardedRef<HTMLInputElement>) => {
    const {
      type = 'text',
      name,
      disabled,
      placeholder,
      prefix,
      suffix,
      onBlur,
    } = props
    const { error, getInputProps, defaultValue, validate } = useField(name)
    const [value, setValue] = useControlField<string>(name)

    return (
      <FieldContainer {...props} id={name}>
        <div className="relative">
          {prefix && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-muted-foreground sm:text-sm">{prefix}</span>
            </div>
          )}
          <Input
            ref={ref}
            disabled={disabled}
            type={type}
            className={clsx(
              defaultValue &&
                value != defaultValue &&
                'ring-2 ring-yellow-300 ring-offset-2',
              !defaultValue && value && 'ring-2 ring-green-400 ring-offset-2',
              prefix && 'pl-7',
              suffix && 'pr-10'
            )}
            {...getInputProps({
              id: name,
              placeholder: placeholder,
              value: value || '',
              onChange: (e) => setValue(e.target.value),
              onBlur: (e) => {
                onBlur?.(e)
                validate()
              },
            })}
          />
          {suffix && (
            <div className="pointer-events-none absolute inset-y-0 right-0 z-0 flex items-center pr-3">
              <span className="text-muted-foreground sm:text-sm">{suffix}</span>
            </div>
          )}
        </div>
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      </FieldContainer>
    )
  }
)

TextField.displayName = 'TextField'

export default TextField
